@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-gray-100 font-serif text-gray-900;
  }

  h1, h2, h3, h4, h5, h6 {
    @apply font-display font-medium;
  }
}

@layer components {
  .magazine-text {
    @apply font-serif text-gray-900;
  }
  
  .magazine-title {
    @apply text-4xl font-bold font-display tracking-wide uppercase;
  }
  
  .magazine-subtitle {
    @apply text-2xl font-semibold font-display tracking-wide;
  }

  .cacm-heading {
    @apply font-display font-bold tracking-wide uppercase;
  }
}

@layer utilities {
  .responsive-text-sm {
    @apply text-xs sm:text-sm md:text-base font-serif;
  }
  
  .responsive-text-base {
    @apply text-sm sm:text-base md:text-lg font-serif;
  }
  
  .responsive-text-lg {
    @apply text-base sm:text-lg md:text-xl font-serif;
  }
  
  .responsive-text-xl {
    @apply text-lg sm:text-xl md:text-2xl font-serif;
  }
  
  .responsive-heading {
    @apply text-2xl sm:text-3xl md:text-4xl font-display font-medium;
  }
}
